import { useState, useEffect, createContext, useContext, useMemo, useRef } from 'react';
import { Magic } from "magic-sdk";
import { isEqual } from 'lodash';
import { identifyUser } from '@propriety/analytics';
import {loadCourtDates} from '../common/helper_functions.js'

// Initialize Magic SDK once at the top level
const magic = new Magic(process.env.REACT_APP_MAGIC_PUBLISHABLE_KEY);

// Export the magic instance
export { magic };

const UserContext = createContext();

// Export the userObject
export const userObject = {
  'james@aventine.ai': { userId: 1, access: {level:'admin',years:null}, userName: 'James', fullName: 'James Burns', organization: 'Aventine Properties' },
  'eileen@aventine.ai': { userId: 2, access: {level:'Training',years:null}, userName: 'Eileen', organization: 'Aventine Properties' },
  'maegan@aventine.ai': { userId: 3, access: {level:'aventine',years:null}, userName: 'Maegan', organization: 'Aventine Properties' },
  'oscar@aventine.ai': { userId: 4, access: {level:'Training',years:null}, userName: 'Oscar', organization: 'Aventine Properties' },
  
  // setup as "analyst, or developer" role that lets peter access the data cleaning stuff
  'peter@aventine.ai': { userId: 5, access: {level:'Training',years:null}, userName: 'Peter', organization: 'Aventine Properties' },
  'claudia@aventine.ai': { userId: 6, access: {level:'Training',years:null}, userName: 'Claudia', organization: 'Aventine Properties' },
  'misbah@aventine.ai': { userId: 10, access: {level:'aventine',years:null}, userName: 'Misbah', organization: 'Aventine Properties' },
  'rogendy@aventine.ai': { userId: 12, access: {level:'aventine',years:null}, userName: 'Rogendy', organization: 'Aventine Properties' },
  'jrbmisand@optonline.net': { userId: 13, access: {level:'admin',years:null}, userName: 'Jim', organization: 'Propriety' },
  
  
  // The propriety users
  'jrburns@aventine.ai': { userId: 16, access: {level:'propadmin',years:null}, userName: 'James Jr', fullName: 'James Burns', organization: 'Propriety' },
  'brennan@aventine.ai': { userId: 19, access: {level:'propadmin',years:null}, userName: 'Brennan', organization: 'Autism Unlimited LLC' },
  // 'max@aventine.ai': { userId: 14, access: {level:'Training',years:null}, userName: 'Max', organization: 'Aventine Properties' },
  // 'lissette@aventine.ai': { userId: 20, access: {level:'Training',years:null}, userName: 'Lissette', organization: 'Aventine Properties' },
  // 'supandeep@aventine.ai': { userId: 21, access: {level:'Training',years:null}, userName: 'Supy', organization: 'Aventine Properties' },
  // 'christina@aventine.ai': { userId: 22, access: {level:'Training',years:null}, userName: 'Christina', organization: 'Aventine Properties' },

  'max@aventine.ai': { userId: 14, access: {level:'aventine',years:null}, userName: 'Max', organization: 'Aventine Properties' },
  'lissette@aventine.ai': { userId: 20, access: {level:'aventine',years:null}, userName: 'Lissette', organization: 'Aventine Properties' },
  'supandeep@aventine.ai': { userId: 21, access: {level:'aventine',years:null}, userName: 'Supy', organization: 'Aventine Properties' },
  'christina@aventine.ai': { userId: 22, access: {level:'aventine',years:null}, userName: 'Christina', organization: 'Aventine Properties' },
};


// W4600-000-000-09820-084
export const isUserAuthorized = (email, organization) => {
  // Check if the email exists in the userObject
  if (userObject[email]) {
    // Optionally, add organization-specific checks here if needed
    return true;
  }
  return false;
};

export const createUserInfo = (email) => {
  if (!userObject[email]) {
    return {
      email,
      userId: null,
      userAccess: false,
      userName: null,
      organization: null,
      lastLoginTime: new Date().toISOString(),
      // Default feature flags as binary values
      manualReview: 1,
      showSettledCases: 1,
      reviewedCasesFlag: 1,
      manualReviewOverride: 0,
      scarOnly: 0,
    };
  }

  const { userId, access, userName, organization } = userObject[email];

  return {
    email,
    userId: userId,
    userAccess: access,
    userName,
    organization,
    lastLoginTime: new Date().toISOString(),
    // Default feature flags as binary values
    manualReview: 1,
    showSettledCases: 1,
    reviewedCasesFlag: 1,
    manualReviewOverride: 0,
    scarOnly: 0,
  };
};

// Enhanced validation function that can be exported
export const validateUserSession = (userInfo) => {
  // Add debug log to track validation calls
  console.log('Validating user session');
  if (!userInfo) {
    localStorage.removeItem('userInfo');
    return false;
  }
  
  // Check if user has access and valid timestamp
  const hasValidAccess = userInfo.userAccess;
  const hasValidSession = isSessionValid(userInfo.lastLoginTime);
  
  if (!hasValidAccess || !hasValidSession) {
    localStorage.removeItem('userInfo');
    return false;
  }
  
  return true;
};

// Check if session is valid within 24h - need to migrate much of this to JWT tokesns / backend flow
const isSessionValid = (lastLoginTime) => {
  // console.log('isSessionValid function to check login time diff', lastLoginTime)
  if (!lastLoginTime) return false;
  const lastLogin = new Date(lastLoginTime);
  const now = new Date();
  const hoursDifference = (now - lastLogin) / (1000 * 60 * 60);
  return hoursDifference < 24;
};

export const UserProvider = ({ children }) => {
  const [userState, setUserState] = useState(() => {
    try {
      const storedUser = localStorage.getItem('userInfo');
      return storedUser ? JSON.parse(storedUser) : null;
    } catch {
      localStorage.removeItem('userInfo');
      return null;
    }
  });
  
  const [courtDates, setCourtDates] = useState([]);
  const [loading, setLoading] = useState(true);
  const isMounted = useRef(true);

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        if (!userState) {
          const isLoggedIn = await magic.user.isLoggedIn();
          if (isLoggedIn && isMounted.current) {
            const userInfo = await magic.user.getInfo();
            const newUser = createUserInfo(userInfo.email);
            setUserState(newUser);
            localStorage.setItem('userInfo', JSON.stringify(newUser));
            if (newUser.userId) {
              identifyUser(newUser.userId, newUser);
            }
            // Load court dates after user is authenticated
            try {
              console.log('loading in first')
              const response = await loadCourtDates();
              if (isMounted.current) {
                setCourtDates(response);
              }
            } catch (error) {
              console.error('Error loading court dates:', error);
            }
          }
        } else {
          // Load court dates for existing user
          try {
            const response = await loadCourtDates();
            if (isMounted.current) {
              setCourtDates(response);
            }
          } catch (error) {
            console.error('Error loading court dates:', error);
          }
        }
      } catch (error) {
        console.error('Auth initialization error:', error);
        setUserState(null);
        localStorage.removeItem('userInfo');
      } finally {
        if (isMounted.current) {
          setLoading(false);
        }
      }
    };

    initializeAuth();

    return () => {
      isMounted.current = false;
    };
  }, []);

  const login = async (userData) => {
    const userWithTimestamp = {
      ...userData,
      lastLoginTime: new Date().toISOString(),
    };
    localStorage.setItem('userInfo', JSON.stringify(userWithTimestamp));
    setUserState((prevState) => 
      isEqual(prevState, userWithTimestamp) ? prevState : userWithTimestamp
    );
    if (userData.userId) {
      console.log('identifying user', userData.userId, userWithTimestamp)
      identifyUser(userData.userId, userWithTimestamp);
    }
    
    // Load court dates after login
    try {
      const response = await loadCourtDates();
      setCourtDates(response);
    } catch (error) {
      console.error('Error loading court dates during login:', error);
    }
  };

  const logout = async () => {
    localStorage.removeItem('userInfo');
    setUserState(null);
    magic.user.logout();
  };

  const contextValue = useMemo(
    () => ({
      user: userState,
      courtDates,
      loading,
      login,
      logout,
    }),
    [userState, courtDates, loading]
  );

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};

export const useUser = () => useContext(UserContext);