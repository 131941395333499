import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useNYData } from '../../common/NyDataObject';
import { useSearchParams } from 'react-router-dom';
import DropdownSection from '../../common/DropdownSelectList';
import { usePersistedDataStore } from '../store';
import {useUser} from '../UserContext'
import { getDefaultYear } from '../../common/helper_functions';

// based on user's access level, dictate what they can select.
// may want to abstract this authentication to a higher level hook or something in the future..
// for this to be secure we need to validate against the user's accesss level on BE (because they could change disabled state / send reqs programatically)
// I didn't build this with county level access in mind. will need to revisit with a county contract. shouldn't be a huge refactor from this
function MuniSelectionV2({ error, handleClearError, locationParams, updateLocationParams, restrictedYear }) {
  // this component shoudl use the strings from params to set the value of the title
    // with relevant specificity - if there is a village, show the village name, otherwise muni, otherwise county, otherwise State.
  const {user} = useUser();
  const getReportType = usePersistedDataStore((state) => state.reportType);
  const [searchParams, setSearchParams] = useSearchParams();

  // Initialize these values from URL params on component mount
  const state = locationParams.state || 'NY';
  const county = locationParams.county || 'All'; // the county code
  const municipality = locationParams.municipality || 'All';
  const village = locationParams.village || '';
  const TaxYear = locationParams.TaxYear || '';
  const municipalityString = locationParams.municipalityString || '';
  const countyString = locationParams.countyString || '';
  const villageString = locationParams.villageString || '';

  const userAccess = user?.userAccess || { level: '', years: [] };
  const isAdmin = userAccess.level === 'admin' || userAccess.level === 'propadmin';
  const isTrainingUser = userAccess.level === 'Training';
  const userRestricted = !isAdmin && userAccess.level !== 'aventine' && !isTrainingUser;
  const runTypeRestricted = getReportType === 'settlement' || locationParams.runType === 'court_date';
  const defaultTaxYear = useMemo(() => {
    const currentYear = getDefaultYear();
    return userAccess?.years?.includes(currentYear) ? currentYear : userAccess?.years?.[0] || currentYear;
  }, [userAccess?.years]);

  const states = ['NY', 'TX', 'FL', 'CA', 'OH'];
  const { getCounties, getMunicipalities, getVillages } = useNYData();

  console.log('rerendering with params,',county)
  const title = useMemo(() => {
    // Depending on the specificity of the selection, show the corresponding title
    if (villageString && villageString !== 'All') {
      return `Village of ${villageString}`;
    } else if (municipalityString && municipalityString !== 'All') {
      return `Town of ${municipalityString}`;
    } else if (countyString && countyString !== 'All') {
      return `${countyString} County`;
    } else if (countyString === 'All') {
      return 'All Counties';
    }
    return state ? `${state} State` : 'New York State';
  }, [villageString, municipalityString, countyString, state]);
  // Get initial values based on userAccess
  const counties = getCounties();
  const countyLookup = useMemo(
    () => Object.fromEntries(counties.map(({ key, selectVal }) => [selectVal, key])),
    [counties]
  );
  
  const municipalities = useMemo(() => {
    if (county) {
      // Use selectedCounty for dynamic lookup
      return getMunicipalities(countyLookup[county]) || [];
    }
    // Fallback to initialCounty if no selection
    return county ? getMunicipalities(countyLookup[county]) : [];
  }, [county, county, getMunicipalities, countyLookup, county]);
  
  const municipalityLookup = useMemo(
    () => Object.fromEntries(municipalities.map(({ key, selectVal }) => [selectVal, key])),
    [municipalities]
  );
  
  const villages = useMemo(() => {
    if (municipality && county) {
      // Use municipality and county for dynamic lookup
      return getVillages(countyLookup[county], municipalityLookup[municipality]) || [];
    }
    // Fallback to initialMunicipality and county if no selection
    return county && municipality
      ? getVillages(countyLookup[county], municipalityLookup[municipality])
      : [];
  }, [
    municipality,
    county,
    getVillages,
    countyLookup,
    municipalityLookup,
  ]);
  const villageLookup = useMemo(
    () => Object.fromEntries(villages.map(({ key, selectVal }) => [selectVal, key])),
    [villages]
  );
  

  const yearOptions = useMemo(() => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth(); // 0-11, where 0 is January
    const showNextYear = currentMonth >= 5; // Show next year if June (5) or later

    return Array.from({ length: showNextYear ? 4 : 3 }, (_, i) => {
      const year = (showNextYear ? currentYear + 1 : currentYear) - i;
      return {
        key: year.toString(),
        value: year,
        displayText: year.toString(),
        disabled: restrictedYear ? year !== parseInt(restrictedYear) : false
      };
    });
  }, [restrictedYear]);

  // Consolidate parameter updates into a single function
  const updateParams = useCallback((updates) => {
    const params = new URLSearchParams(window.location.search);
    const finalUpdates = { ...updates };

    // Process all parameter updates at once
    Object.entries(updates).forEach(([key, value]) => {
      switch (key) {
        case 'state':
          params.set('state', value);
          // Clear dependent fields
          ['county', 'countyString', 'municipality', 'municipalityString', 'village', 'villageString', 'MuniCode'].forEach(k => {
            params.delete(k);
            finalUpdates[k] = '';
          });
          break;

        case 'county':
          params.set('county', value);
          params.set('countyString', countyLookup[value] || 'All');
          params.set('MuniCode', value);
          finalUpdates.countyString = countyLookup[value] || 'All';
          finalUpdates.MuniCode = value;
          // Clear dependent fields
          ['municipality', 'municipalityString', 'village', 'villageString'].forEach(k => {
            params.delete(k);
            finalUpdates[k] = '';
          });
          break;

        case 'municipality':
          const countyCode = params.get('county') || '';
          const newMuniCode = value !== 'All' ? countyCode + value : countyCode;
          params.set('municipality', value);
          params.set('municipalityString', municipalityLookup[value] || 'All');
          params.set('MuniCode', newMuniCode);
          finalUpdates.municipalityString = municipalityLookup[value] || 'All';
          finalUpdates.MuniCode = newMuniCode;
          // Clear dependent fields
          ['village', 'villageString'].forEach(k => {
            params.delete(k);
            finalUpdates[k] = '';
          });
          break;

        case 'village':
          const muniCode = params.get('MuniCode') || '';
          const newVillageMuniCode = value !== 'All' ? muniCode.slice(0, 1) + value : muniCode.slice(0, 3);
          params.set('village', value);
          params.set('villageString', villageLookup[value] || 'All');
          params.set('MuniCode', newVillageMuniCode);
          finalUpdates.villageString = villageLookup[value] || 'All';
          finalUpdates.MuniCode = newVillageMuniCode;
          break;

        case 'TaxYear':
          params.set('TaxYear', value);
          break;

        default:
          params.set(key, value);
      }
    });

    // Single URL update
    setSearchParams(params, { replace: true });
    // Single state update
    updateLocationParams(finalUpdates);
  }, [setSearchParams, updateLocationParams, countyLookup, municipalityLookup, villageLookup]);

  // Replace handleDropdownChange with direct usage of updateParams
  const handleDropdownChange = useCallback((key, value) => {
    // Clear any existing error for this field
    if (error?.[key]) {
      handleClearError(key);
    }
    updateParams({ [key]: value });
  }, [updateParams, error, handleClearError]);

  const dropdowns = useMemo(() => [
    // State
    {
      key: 'state',
      label: 'State',
      options: states.map((state) => ({
        key: state,
        value: state,
        displayText: state,
        // disabled: userRestricted && state !== state, // Restrict selection only if user is restricted
        disabled: state !== state, // Restrict selection only if user is restricted
      })),
      value: state,
      width: 60,
    },
    // County
    {
      key: 'county',
      label: 'County',
      options: counties.map(({ key, selectVal, nameWithSelectVal }) => ({
        key,
        value: selectVal,
        displayText: nameWithSelectVal,
        disabled: userRestricted && selectVal !== county, // Restrict selection only if user is restricted
      })),
      value: county,
      disabled: userRestricted && !county || runTypeRestricted, // Disable dropdown entirely if no county access is defined
      // error state here, if value exists, error = true.
      error: error?.county,
      width: 130,
    },
    // Municipality
    {
      key: 'municipality',
      label: 'Municipality',
      options: municipalities.map(({ key, selectVal, nameWithSelectVal }) => ({
        key,
        value: selectVal,
        displayText: nameWithSelectVal,
        disabled: userRestricted && selectVal !== municipality, // Restrict selection only if user is restricted
      })),
      value: municipality,
      error: error?.municipality,
      disabled: (userRestricted || municipalities.length===0) && (!county || county === 'All' || municipalities.length === 0) || runTypeRestricted,
      width: 130,
    },
    // Village
    {
      key: 'village',
      label: 'Village',
      options: villages.map(({ key, selectVal, nameWithSelectVal }) => ({
        key,
        value: selectVal,
        displayText: nameWithSelectVal,
      })),
      value: village,
      // error: error?.village,
      disabled: (userRestricted || villages.length === 0) && (!municipality || villages.length === 0) || runTypeRestricted, // Restrict selection only if user is restricted
      width: 130,
    },
    // Tax Year
    {
      key: 'TaxYear',
      label: 'Tax Year',
      options: yearOptions,
      value: TaxYear || '',
      error: error?.TaxYear,
      disabled: !!restrictedYear || runTypeRestricted || userRestricted && userAccess?.years?.length === 0,
      width: 110,
    },
  ], [
    states,
    counties,
    municipalities,
    villages,
    error,
    state,
    county,
    municipality,
    village,
    TaxYear,
    userRestricted, // Dependency for restriction flag
    restrictedYear,
  ]);

  return <DropdownSection dropdowns={dropdowns} onDropdownChange={handleDropdownChange} title={title}/>;
}

export default MuniSelectionV2;