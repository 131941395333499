import React, { useCallback, useState, useMemo } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import debounce from 'lodash/debounce';
import { NumericFormat } from 'react-number-format';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { toast } from 'react-toastify';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
    />
  );
});

export default function MarketingInputs({ 
  marketingResponse, 
  title, 
  minimumThreshold,
  setMinimumThreshold,
  minimumRecentSaleRevenue,
  highTaxCutoff,
  setHighTaxCutoff
}) {
  const [localMinThreshold, setLocalMinThreshold] = useState(minimumThreshold.toString());
  const [localHighTaxCutoff, setLocalHighTaxCutoff] = useState(highTaxCutoff.toString());
  const [conversionRate, setConversionRate] = useState('1'); // Default 10%
  const [winRate, setWinRate] = useState('50'); // Default 50%
  const [mailingCost, setMailingCost] = useState('0.44');
  const [updatingFields, setUpdatingFields] = useState({
    revenue: false,
    tax: false,
    conversion: false,
    winRate: false,
    mailingCost: false
  });
  const [showCaseBreakdown, setShowCaseBreakdown] = useState(false);

  // Calculate filtered results
  const results = useMemo(() => {
    if (!marketingResponse) return {
      selectedCount: 0,
      highTaxMailings: 0,
      totalMailings: 0,
      estimatedRevenue: 0,
      totalMailingCost: 0,
      netRevenue: 0,
      estimatedClientCount: 0,
      caseTypeCounts: {
        regularMail: 0,
        strongCaseIndex: 0,
        strongCaseArgument: 0,
        strongCaseRatio: 0,
        strongCaseCount: 0,
        recentSale: 0,
        strongCaseTotal: 0
      }
    };
    
    // First filter by minimum revenue threshold
    const revenueFiltered = marketingResponse.filter(row => 
      row.ProjectedAventineRevenue >= Number(minimumThreshold) || row.CaseDescription === "RecentSale"
    );

    // Separate high tax filtering
    const highTaxFiltered = marketingResponse.filter(row => 
      row.Taxes >= Number(highTaxCutoff) && 
      (row.CaseDescription !== "BadRecentSale") &&
      // && row.ProjectedAventineRevenue >= minimumRecentSaleRevenue
      (!row.LetterType?.toLowerCase().includes('regularmail') && !row.LetterType?.toLowerCase().includes('recentsale') || row.CaseDescription?.toLowerCase().includes('hightax'))
    );

    // Initialize counts object
    const counts = revenueFiltered.reduce((acc, row) => {
      const caseDesc = (row.CaseDescription || '').toLowerCase();
      acc.totalMailings++;
      
      // Recent Sales (Priority 1) -- we removed the minimumRecentSale rev filter since it was confusing for counts.
      //  && row.ProjectedAventineRevenue >= minimumRecentSaleRevenue
      if (row.CaseDescription === "RecentSale") {
        acc.recentSale++;
        acc.strongCaseTotal++;
        return acc;
      }
      
      // Strong Cases and Regular Mail
      if (caseDesc.includes('strongcase_count')) {
        acc.strongCaseCount++;
        acc.strongCaseTotal++;
      } else if (caseDesc.includes('strongcase_index')) {
        acc.strongCaseIndex++;
        acc.strongCaseTotal++;
      } else if (caseDesc.includes('strongcase_argument')) {
        acc.strongCaseArgument++;
        acc.strongCaseTotal++;
      } else if (caseDesc.includes('strongcase_ratio')) {
        acc.strongCaseRatio++;
        acc.strongCaseTotal++;
      } else if (caseDesc.includes('regularmail')) {
        acc.regularMail++;
      }
      
      return acc;
    }, {
      regularMail: 0,
      strongCaseIndex: 0,
      strongCaseArgument: 0,
      strongCaseRatio: 0,
      strongCaseCount: 0,
      recentSale: 0,
      strongCaseTotal: 0,
      highTaxMailings: highTaxFiltered.length, // Set high tax count from separate filtering
      totalMailings: 0
    });

    // Add high tax mailings to total mailings
    counts.totalMailings += counts.highTaxMailings;

    const totalMailings = counts.totalMailings;
    const costPerMailing = mailingCost === '' ? 0 : Number(mailingCost);
    const totalMailingCost = totalMailings * costPerMailing;
    const conversionRateDecimal = conversionRate === '' ? 0 : Math.min(Number(conversionRate), 100) / 100;
    const winRateDecimal = winRate === '' ? 0 : Math.min(Number(winRate), 100) / 100;
    const estimatedRevenue = revenueFiltered.reduce((sum, row) => sum + (row.ProjectedAventineRevenue || 0), 0) 
      * conversionRateDecimal 
      * winRateDecimal;
    
    return {
      selectedCount: revenueFiltered.length,
      highTaxMailings: counts.highTaxMailings,
      totalMailings,
      estimatedClients: Math.round(totalMailings * conversionRateDecimal),
      estimatedRevenue,
      totalMailingCost,
      netRevenue: estimatedRevenue - totalMailingCost,
      estimatedClientCount: revenueFiltered.length,
      caseTypeCounts: counts
    };
  }, [marketingResponse, minimumThreshold, highTaxCutoff, conversionRate, winRate, mailingCost]);

  const debouncedSetMinRevenueCutoff = useCallback(
    debounce((value) => {
      setMinimumThreshold(Number(value));
      setUpdatingFields(prev => ({ ...prev, revenue: false }));
    }, 300),
    [setMinimumThreshold]
  );

  const debouncedSetHighTaxCutoff = useCallback(
    debounce((value) => {
      setHighTaxCutoff(Number(value));
      setUpdatingFields(prev => ({ ...prev, tax: false }));
    }, 300),
    [setHighTaxCutoff]
  );

  const debouncedSetConversionRate = useCallback(
    debounce((value) => {
      if (value === '') {
        setConversionRate('');
      } else {
        const boundedValue = Math.min(Math.max(0, value), 100);
        setConversionRate(boundedValue);
      }
      setUpdatingFields(prev => ({ ...prev, conversion: false }));
    }, 300),
    []
  );

  const debouncedSetWinRate = useCallback(
    debounce((value) => {
      if (value === '') {
        setWinRate('');
      } else {
        const boundedValue = Math.min(Math.max(0, value), 100);
        setWinRate(boundedValue);
      }
      setUpdatingFields(prev => ({ ...prev, winRate: false }));
    }, 300),
    []
  );

  const debouncedSetMailingCost = useCallback(
    debounce((value) => {
      setMailingCost(value);
      setUpdatingFields(prev => ({ ...prev, mailingCost: false }));
    }, 300),
    []
  );

  const handleMinThresholdChange = (e) => {
    const value = e.target.value;
    setLocalMinThreshold(value);
    setUpdatingFields(prev => ({ ...prev, revenue: true }));
    debouncedSetMinRevenueCutoff(value);
  };

  const handleHighTaxCutoffChange = (e) => {
    const value = e.target.value;
    setLocalHighTaxCutoff(value);
    setUpdatingFields(prev => ({ ...prev, tax: true }));
    debouncedSetHighTaxCutoff(value);
  };

  const handleInputChange = (setter, field) => (e) => {
    setUpdatingFields(prev => ({ ...prev, [field]: true }));
    let value = e.target.value;
    
    // Only remove leading zeros for fields other than conversion
    if (field !== 'conversion' && field !== 'mailingCost' && value.length > 1 && value.startsWith('0')) {
      value = value.replace(/^0+/, '');
    }
    
    // Immediately update the input value for fluid typing
    if (field === 'conversion') {
      setConversionRate(value);
    } else if (field === 'winRate') {
      setWinRate(value);
    } else if (field === 'mailingCost') {
      setMailingCost(value);
    }
    
    setter(value);
  };

  const prepareCSVData = (results) => {
    return [
      ['Category', 'Value'],
      ['Total Mailings', results.totalMailings],
      ['Strong Cases', results.caseTypeCounts.strongCaseTotal],
      ['Regular Mail', results.caseTypeCounts.regularMail],
      ['High Tax', results.highTaxMailings],
      ['Recent Sale', results.caseTypeCounts.recentSale],
      ['Strong (Count)', results.caseTypeCounts.strongCaseCount],
      ['Strong (Index)', results.caseTypeCounts.strongCaseIndex],
      ['Strong (Argument)', results.caseTypeCounts.strongCaseArgument],
      ['Strong (Ratio)', results.caseTypeCounts.strongCaseRatio],
      ['Estimated Clients', results.estimatedClients],
      ['Estimated Revenue', results.estimatedRevenue],
      ['Mailing Cost', results.totalMailingCost],
      ['Net Revenue', results.netRevenue],
      ['ROI', results.totalMailingCost ? (results.netRevenue / results.totalMailingCost).toFixed(2) + 'x' : '0.00x']
    ].map(row => row.join(',')).join('\n');
  };

  const handleCopyResults = () => {
    const resultsText = `${title} Marketing:

Total Mailings: ${results.totalMailings.toLocaleString()}
Strong Cases: ${results.caseTypeCounts.strongCaseTotal.toLocaleString()}
Regular Mail: ${results.caseTypeCounts.regularMail.toLocaleString()}
High Tax: ${results.highTaxMailings.toLocaleString()}
Recent Sale: ${results.caseTypeCounts.recentSale.toLocaleString()}
Strong (Count): ${results.caseTypeCounts.strongCaseCount.toLocaleString()}
Strong (Index): ${results.caseTypeCounts.strongCaseIndex.toLocaleString()}
Strong (Argument): ${results.caseTypeCounts.strongCaseArgument.toLocaleString()}
Strong (Ratio): ${results.caseTypeCounts.strongCaseRatio.toLocaleString()}
Estimated Clients: ${results.estimatedClients.toLocaleString()}
Estimated Revenue: $${results.estimatedRevenue.toLocaleString(undefined, {maximumFractionDigits: 0})}
Mailing Cost: $${results.totalMailingCost.toLocaleString(undefined, {maximumFractionDigits: 0})}
Net Revenue: $${results.netRevenue.toLocaleString(undefined, {maximumFractionDigits: 0})}
ROI: ${results.totalMailingCost ? `${(results.netRevenue / results.totalMailingCost).toFixed(2)}x` : '0.00x'}`;

    navigator.clipboard.writeText(resultsText);
    toast.success('Results copied to clipboard');
  };

  const handleExportCSV = () => {
    const csvContent = prepareCSVData(results);
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'marketing_results.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Paper 
      maxWidth="2xl"
      className="w-full"
      sx={{ 
        p: 2,
        mx: 'auto',
        justifyContent: 'center'
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} sx={{ mx: 'auto' }}>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center' }}>
            <Box sx={{minWidth: '100px', width: '150px' }}>
              <TextField
                fullWidth
                size="small"
                label="Min Revenue"
                value={localMinThreshold}
                onChange={handleMinThresholdChange}
                InputProps={{
                  startAdornment: <Typography variant="body2" sx={{ mr: 1 }}>$</Typography>,
                  inputComponent: NumericFormatCustom
                }}
              />
            </Box>
            <Box sx={{ minWidth: '100px', width: '150px' }}>
              <TextField
                fullWidth
                size="small"
                label="High Tax"
                value={localHighTaxCutoff}
                onChange={handleHighTaxCutoffChange}
                InputProps={{
                  startAdornment: <Typography variant="body2" sx={{ mr: 1 }}>$</Typography>,
                  inputComponent: NumericFormatCustom
                }}
              />
            </Box>
            <Box sx={{ minWidth: '100px', width: '150px' }}>
              <TextField
                fullWidth
                size="small"
                label="Response Rate"
                type="number"
                value={conversionRate}
                onChange={handleInputChange(debouncedSetConversionRate, 'conversion')}
                InputProps={{
                  endAdornment: <Typography variant="body2" sx={{ ml: 1 }}>%</Typography>,
                  inputProps: { min: "0", max: "100", step: "1" }
                }}
              />
            </Box>
            <Box sx={{minWidth: '100px', width: '150px' }}>
              <TextField
                fullWidth
                size="small"
                label="Win Rate"
                type="number"
                value={winRate}
                onChange={handleInputChange(debouncedSetWinRate, 'winRate')}
                InputProps={{
                  endAdornment: <Typography variant="body2" sx={{ ml: 1 }}>%</Typography>,
                  inputProps: { min: "0", max: "100", step: "1" }
                }}
              />
            </Box>
            <Box sx={{ minWidth: '100px', width: '150px' }}>
              <TextField
                fullWidth
                size="small"
                label="Cost/Letter"
                value={mailingCost}
                onChange={handleInputChange(debouncedSetMailingCost, 'mailingCost')}
                InputProps={{
                  startAdornment: <Typography variant="body2" sx={{ mr: 1 }}>$</Typography>,
                }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            gap: 0.5,
            alignItems: 'center',
            position: 'relative',
          }}>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              flexWrap: 'wrap', 
              justifyContent: 'center',
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '200px', justifyContent: 'center' }}>
                <Typography variant="body2" sx={{ textAlign: 'center' }}>
                  Total Mailings: <strong>{results.totalMailings.toLocaleString()}</strong>
                </Typography>
                {(updatingFields.revenue || updatingFields.tax) && <CircularProgress size={16} />}
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '200px', justifyContent: 'center' }}>
                <Typography variant="body2" sx={{ textAlign: 'center' }}>
                  Strong Cases: <strong>{results.caseTypeCounts.strongCaseTotal}</strong>
                </Typography>
              </Box>
              <Box 
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: 1, 
                  width: '200px', 
                  justifyContent: 'center',
                  cursor: 'pointer'
                }}
                onClick={() => setShowCaseBreakdown(!showCaseBreakdown)}
              >
                <Typography variant="body2" sx={{ textAlign: 'center' }}>
                  Case Breakdown
                </Typography>
                <ExpandMoreIcon 
                  sx={{ 
                    transform: showCaseBreakdown ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform 0.3s'
                  }} 
                />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '200px', justifyContent: 'center' }}>
                <Typography variant="body2" sx={{ textAlign: 'center' }}>
                  Estimated Clients: <strong>{results.estimatedClients.toLocaleString()}</strong>
                </Typography>
                {(updatingFields.revenue || updatingFields.tax || updatingFields.conversion) && <CircularProgress size={16} />}
              </Box>
            </Box>

            <Collapse in={showCaseBreakdown} sx={{ width: '100%' }}>
              <Box sx={{ 
                display: 'flex', 
                flexWrap: 'wrap', 
                justifyContent: 'center',
                gap: 2,
                p: 1,
                bgcolor: 'background.paper',
                borderRadius: 1,
              }}>
                <Typography variant="body2">
                  Regular Mail: <strong>{results.caseTypeCounts.regularMail}</strong>
                </Typography>
                <Typography variant="body2">
                  High Tax: <strong>{results.highTaxMailings}</strong>
                </Typography>
                <Typography variant="body2">
                  Recent Sale: <strong>{results.caseTypeCounts.recentSale}</strong>
                </Typography>
                <Typography variant="body2">
                  Strong (Count): <strong>{results.caseTypeCounts.strongCaseCount}</strong>
                </Typography>
                <Typography variant="body2">
                  Strong (Index): <strong>{results.caseTypeCounts.strongCaseIndex}</strong>
                </Typography>
                <Typography variant="body2">
                  Strong (Argument): <strong>{results.caseTypeCounts.strongCaseArgument}</strong>
                </Typography>
                <Typography variant="body2">
                  Strong (Ratio): <strong>{results.caseTypeCounts.strongCaseRatio}</strong>
                </Typography>
              </Box>
            </Collapse>

            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column',
              gap: 0.5,
              alignItems: 'center',
              position: 'relative',
            }}>
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                flexWrap: 'wrap', 
                justifyContent: 'center',
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '200px', justifyContent: 'center' }}>
                  <Typography variant="body2" sx={{ textAlign: 'center' }}>
                    Estimated Revenue: <strong>${results.estimatedRevenue.toLocaleString(undefined, {maximumFractionDigits: 0})}</strong>
                  </Typography>
                  {(updatingFields.revenue || updatingFields.conversion || updatingFields.winRate) && <CircularProgress size={16} />}
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '200px', justifyContent: 'center' }}>
                  <Typography variant="body2" sx={{ textAlign: 'center' }}>
                    Mailing Cost: <strong>${results.totalMailingCost.toLocaleString(undefined, {maximumFractionDigits: 0})}</strong>
                  </Typography>
                  {(updatingFields.revenue || updatingFields.tax || updatingFields.mailingCost) && <CircularProgress size={16} />}
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '200px', justifyContent: 'center' }}>
                  <Typography variant="body2" sx={{ textAlign: 'center' }}>
                    Net Revenue: <strong>${results.netRevenue.toLocaleString(undefined, {maximumFractionDigits: 0})}</strong>
                  </Typography>
                  {(updatingFields.revenue || updatingFields.tax || updatingFields.conversion || updatingFields.winRate || updatingFields.mailingCost) && 
                    <CircularProgress size={16} />}
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '200px', justifyContent: 'center' }}>
                  <Typography variant="body2" sx={{ textAlign: 'center' }}>
                    ROI: <strong>{results.totalMailingCost ? `${(results.netRevenue / results.totalMailingCost).toFixed(2)}x` : '0.00x'}</strong>
                  </Typography>
                  {(updatingFields.revenue || updatingFields.tax || updatingFields.conversion || updatingFields.winRate || updatingFields.mailingCost) && 
                    <CircularProgress size={16} />}
                  <IconButton 
                    onClick={handleCopyResults}
                    size="small"
                  >
                    <ContentCopyIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}