import React, { useState } from 'react';
import AdjustmentsDialog from './AdjustmentsDialog';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Switch,
  Checkbox,
  Box,
  Typography,
  Slider,
  Tabs,
  Tab,
  FormHelperText,
  TextField,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import WeightsViewRebuilt from './WeightsViewRebuilt';
import { useLocalDataStore, useCompStore, usePersistedDataStore } from '../store';
import dayjs from 'dayjs';

const FIELD_TYPES = {
  TOGGLE: 'toggle',
  MULTICHECK: 'multicheck',
  SLIDER: 'slider',
};

function AdvancedSettings({ open, onClose, onConfirm, initialSettings={}, runAnalysis}) {
  // console.log('Initial Settings:', initialSettings);
  const [activeTab, setActiveTab] = useState(0);
  const [settings, setSettings] = useState({
    manualReview: initialSettings.manualReview ?? true,
    defaultRunTypes: initialSettings.defaultRunTypes ?? ['settled'],
    compFilters: initialSettings.compFilters ?? [],
    dateRange: initialSettings.dateRange ?? [null, null],
    squareFeetFilter: initialSettings.squareFeetFilter ?? 40,
    salePriceFilter: initialSettings.salePriceFilter ?? 40,
    acreageFilter: initialSettings.acreageFilter ?? 40,
  });
  // const [weightsOpen, setWeightsOpen] = useState(false);

  // const [settingsUpdated, setSettingsUpdated] = useState(false);
  const settingsUpdated = useLocalDataStore((state) => state.settingsUpdated)
  // console.log(settingsUpdated)
  const setSettingsUpdated = useLocalDataStore((state) => state.setSettingsUpdated)
  const getAdjustments = useCompStore((state) => state.adjustments);
  const getCompSheet = usePersistedDataStore((state) => state.compSheet);
  function normalizeDate(date) {
    // Convert to a Day.js object if not already in a valid format
    return dayjs(date).isValid() ? dayjs(date) : null;
  }

  const handleTabChange = (e, newValue) => {
    setActiveTab(newValue);
    // if (newValue === 3) {
    //   setWeightsOpen(true);
    // }
  };

  const handleClose = () => {
    if(settingsUpdated){setSettingsUpdated(false)}
    onClose();

  };

  const handleFieldChange = (name, value) => {
    setSettings((prev) => ({ ...prev, [name]: value }));
    if(!settingsUpdated){setSettingsUpdated(true);}
  };

  const savePage = () => {

    if(onConfirm){
      onConfirm(settings);
    }

    // Main page settings
    if(getCompSheet.length > 0){
      console.log('it should re-run the KNN here on save')
      // need to do this for weights view too
    }
  }

  const renderSalesAnalysisTab = () => (
    <>
      <Typography variant="h6" sx={{ mb: 0 }}>Sales Analysis Settings</Typography>
      <Typography variant="body2" sx={{ mb: 2 }}>
        The selections you make will be saved as your default options.
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{display: 'flex', gap: 0, alignItems: 'center'}}>
      <FormControlLabel
        className="items-center"
        control={
            <Switch
            checked={settings.manualReview}
            onChange={(e) => handleFieldChange('manualReview', e.target.checked)}
            sx={{
                pointerEvents: 'auto', // Ensure the toggle itself is clickable
            }}
            />
        }
        label="Enable Manual Review"
        sx={{
            alignItems: 'flex-start',
            pointerEvents: 'none', // Disable container-level clicks
            '& .MuiSwitch-root': {
            pointerEvents: 'auto', // Ensure the Switch is independently clickable
            },
            '& .MuiFormControlLabel-label': {
            pointerEvents: 'auto', // Ensure the label text is independently clickable
            },
        }}
        />
        {!settings.manualReview && (
            <FormHelperText sx={{ color: 'red' }}>*Manual Review = False, This will autosave comps</FormHelperText>
        )}
        </Box>

        {/* Subject filtering selection */}
        <FormControl component="fieldset" >
          <FormLabel className=''>Subject Filtering:</FormLabel>
          <FormGroup row>
            {[
              { value: 'scarFiled', label: 'Show SCAR Filed Only' },
              { value: 'settled', label: 'Show Settled Cases' },
              // { value: 'MLS', label: 'Show Settled Only' },
            ].map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox
                    checked={settings.defaultRunTypes.includes(option.value)}
                    // disabled={true}
                    onChange={(e) => {
                      const newValues = e.target.checked
                        ? [...settings.defaultRunTypes, option.value]
                        : settings.defaultRunTypes.filter((val) => val !== option.value);
                      handleFieldChange('defaultRunTypes', newValues);
                    }}
                  />
                }
                label={option.label}
              />
            ))}
          </FormGroup>
        </FormControl>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <FormControl component="fieldset" >
          <FormLabel className=''>Comp Filtering:</FormLabel>
          <FormGroup row>
            {[
              { value: 'mlsOnly', label: 'Only use MLS Comps' },
              // { value: 'allowConditionIssues', label: 'Allow Condition Issues' },
            ].map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox
                    checked={settings.compFilters.includes(option.value)}
                    onChange={(e) => {
                      const newValues = e.target.checked
                        ? [...settings.compFilters, option.value]
                        : settings.compFilters.filter((val) => val !== option.value);
                      handleFieldChange('compFilters', newValues);
                    }}
                  />
                }
                label={option.label}
              />
            ))}
          </FormGroup>
        </FormControl>
        {/* Here, title which displays "Comp selection settings" */}
<LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <DatePicker
          label="Earliest Sale Date"
          value={normalizeDate(settings.dateRange[0])}
          onChange={(newDate) =>
            handleFieldChange('dateRange', [
              newDate ? newDate.format('YYYY-MM-DD') : null,
              settings.dateRange[1],
            ])
          }
          slotProps={{
            textField: { size: 'small' },
          }}
        />
        <DatePicker
          label="Last Sale Date Cutoff"
          value={normalizeDate(settings.dateRange[1])}
          onChange={(newDate) =>
            handleFieldChange('dateRange', [
              settings.dateRange[0],
              newDate ? newDate.format('YYYY-MM-DD') : null,
            ])
          }
          slotProps={{
            textField: { size: 'small' },
          }}
        />
      </Box>
    </LocalizationProvider>
        </Box>
      <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>Hard Filter Features</Typography>
      <Typography variant="body2" sx={{ mb: 2 }}>
        Do not show comps that are x% less than subjects:
      </Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
        <FormControl sx={{ flex: '1 1 45%' }}>
          <FormLabel>Square Feet Filter ({settings.squareFeetFilter}%)</FormLabel>
          <Slider
            value={settings.squareFeetFilter}
            onChange={(e, value) => handleFieldChange('squareFeetFilter', value)}
            valueLabelDisplay="auto"
            min={0}
            disabled
            max={100}
          />
        </FormControl>
        <FormControl sx={{ flex: '1 1 45%' }}>
          <FormLabel>Acreage Filter ({settings.acreageFilter}%)</FormLabel>
          <Slider
            value={settings.acreageFilter}
            onChange={(e, value) => handleFieldChange('acreageFilter', value)}
            valueLabelDisplay="auto"
            disabled
            min={0}
            max={100}
          />
        </FormControl>
        <FormControl sx={{ flex: '1 1 45%' }}>
          <FormLabel>Sale Price Filter ({settings.salePriceFilter}%)</FormLabel>
          <Slider
            value={settings.salePriceFilter}
            onChange={(e, value) => handleFieldChange('salePriceFilter', value)}
            valueLabelDisplay="auto"
            disabled
            min={0}
            max={100}
          />
        </FormControl>
      </Box>

    <div  className="absolute right-2 bottom-2 justify-end">
      <Button onClick={handleClose}>Close</Button>
        <Button
          onClick={() => {
            if(settingsUpdated){setSettingsUpdated(false);}
            savePage()
            handleClose();
          }}
          variant="contained"
          disabled={!settingsUpdated}
        >
          Save
        </Button>
        </div>

{/* Selection options for sales analysis */}
    </>
  );

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xl"   sx={{
      '& .MuiDialog-paper': {
        minHeight: '700px !important', // Ensure min-height is applied to the Dialog content
      },
    }}>
      <DialogTitle>Advanced Settings</DialogTitle>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        sx={{
            '& .MuiTab-root': {
            opacity: 1, // Ensure non-disabled tabs are fully visible
            },
            '& .MuiTab-root.Mui-disabled': {
            backgroundColor: 'transparent !important', // Remove background for disabled tabs
            color: 'gray !important', // Set text color to gray
            },
        }}
        >
            <Tab label="Sales Analysis" />
            <Tab label="Equity Analysis" disabled />
            <Tab label="Marketing" disabled />
            <Tab label="Adjustments" disabled={Object.keys(getAdjustments) < 1}/>
            <Tab label="Model Weights" />
      </Tabs>

      <DialogContent>
        {activeTab === 0 && renderSalesAnalysisTab()}
        {activeTab === 3 && (
            <AdjustmentsDialog
              open={true} // Always render this within the tab
              onClose={() => handleClose()} // Switch back to the first tab on close
              // onClose={() => setActiveTab(0)} // Switch back to the first tab on close
              // onConfirm
            />
        )}

        {activeTab === 4 && (
            <WeightsViewRebuilt
              open={true} // Always render this within the tab
              onClose={() => handleClose()} // Switch back to the first tab on close
              runAnalysis={runAnalysis}
            />
        )}
        {/* {activeTab !== 0 && <Typography variant="body2">This tab is under construction.</Typography>} */}
      </DialogContent>
      

      {/* Update this so it is versitile for either page. */}
      {/* <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button
          onClick={() => {
            // console.log('Settings Saved:', settings);
            if(settingsUpdated){setSettingsUpdated(false);}
            // migrate the "onconfirm" function into this, and fire an (optional) callback to do the onconfirm
            savePage()
            handleClose();
          }}
          variant="contained"
          disabled={!settingsUpdated}
        >
          Save
        </Button>
      </DialogActions> */}
    </Dialog>
  );
}

export default AdvancedSettings;